<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-chart" data="green">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2 class="card-title">Campagnes</h2>
                <h5 class="card-category" style="color:#000">by Weedo IT</h5>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons"> 
                  <base-button round type="default" class="btn-just-icon mx-auto d-block">Découvrir toutes les campagnes</base-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="background: #fff">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-6" style="color:#000; text-align: center">
                <h5 style="color:#000"><strong>Audika </strong></h5>
                <img src="/img/audika.jpg" width="170" height="120">
                <p style="color:#000; font-size:13px">Co-registration<br><strong>1.5€</strong></p> 
                <base-button round type="success" class="btn-just-icon">Contact</base-button>
              </div>
              <div class="col-lg-3 col-md-4 col-6" style="color:#000; text-align: center">
                <h5 style="color:#000"><strong>Calinia chien </strong></h5>
                <img src="/img/eca.jpg" width="170" height="120">
                <p style="color:#000; font-size:13px">Co-registration<br><strong>1.5€</strong></p> 
                <base-button round type="success" class="btn-just-icon">Contact</base-button>
              </div>
              <div class="col-lg-3 col-md-4 col-6" style="color:#000; text-align: center">
                <h5 style="color:#000"><strong>Self epargne </strong></h5>
                <img src="/img/self.jpg" width="170" height="120">
                <p style="color:#000; font-size:13px">Co-registration<br><strong>1.5€</strong></p> 
                <base-button round type="success" class="btn-just-icon">Contact</base-button>
              </div>
              <div class="col-lg-3 col-md-4 col-6" style="color:#000; text-align: center">
                <h5 style="color:#000"><strong>Kusmi Tea CH </strong></h5>
                <img src="/img/kusmi.jpg" width="170" height="120">
                <p style="color:#000; font-size:13px">Co-registration<br><strong>1.5€</strong></p> 
                <base-button round type="success" class="btn-just-icon">Contact</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </diV>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="1 618 €"
          sub-title="CA De La Veille"
          type="success"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="851 €"
          sub-title="CA Du Jour"
          type="success"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>
      <div class="col-lg-4 col-md-6">
        <stats-card
          title="28 936 €"
          sub-title="CA Du Mois"
          type="success"
          icon="tim-icons icon-coins"
        >
        </stats-card>
      </div>

      <div class="col-lg-6">
        <div class="card card-chart" data="green">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2 class="card-title">Stats</h2>
                <h5 class="card-category" style="color:#000">du jour</h5>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-area">
              <line-chart
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>  
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card card-chart" data="green">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2 class="card-title">Stats</h2>
                <h5 class="card-category" style="color:#000">du mois</h5>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                  <label
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option.name"
                    class="btn btn-sm btn-default btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    <span class="d-none d-sm-block">{{ option.name }}</span>
                    <span class="d-block d-sm-none">
                      <i :class="option.icon"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-area">
              <line-chart
                style="height: 100%"
                ref="bigChart"
                :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors"
                :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions"
              >
              </line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from 'src/components/Cards/StatsCard';
import LineChart from '@/components/Charts/LineChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';

let bigChartData = [
  [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
  [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
  [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
]
let bigChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  components: {
    StatsCard,
    LineChart
  },
  data(){
    return {
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
    }
  },
  computed: {
    bigLineChartCategories() {
      return [{ name: 'CA', icon: 'tim-icons icon-single-02' }, { name: 'Campagnes', icon: 'tim-icons icon-gift-2' }, { name: 'Contact', icon: 'tim-icons icon-tap-02' }];
    }
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    }
  },
  mounted() {
    this.initBigChart(0);
  }
};
</script>
<style></style>
